<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Videos list -->
    <div
      v-if="!isLoading"
      class="flex flex-wrap items-stretch mt-9"
      key="content"
    >
      <VideoCard
        v-for="video in videos"
        :key="video.id"
        :id="video.id"
        :title="video.name"
        :expert="video.expert"
        :specialities="video.specialitiesText"
        :isFavourite="video.isFavourite"
        :link="videoUrl + video.id"
        :isAvailable="video.isPublic || isUserHaveAccess"
        :likes="video.likes"
        :isLiked="video.like"
        type="single"
      />
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import VideoCard from '@/components/videos/VideoCard.vue'

export default {
  name: 'Videos',
  components: {
    VideoCard,
    LoadingScreen,
    FadeTransition
  },
  data: function() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      videos: 'videos/getFilteredVideos',
      isUserHaveAccess: 'isUserHaveAccess'
    }),
    videoUrl: function() {
      return '/' + this.$route.params.therapyarea_url + '/videos/';
    }
  },
  methods: {
    ...mapActions({
      getTherapyAreaVideos: 'videos/getTherapyAreaVideos'
    })
  },
  created: function() {
    this.getTherapyAreaVideos(this.$route.params.therapyarea_url)
      .then(response => {
        this.isLoading = false
      })
      .catch()
  }
}
</script>
